export const photos = [
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 2048,
    height: 1365,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 2048,
    height: 1365,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 200,
    height: 200,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 200,
    height: 200,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 200,
    height: 200,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 200,
    height: 200,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 1686,
    height: 1125,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 200,
    height: 200,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 643,
    height: 960,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 1686,
    height: 1125,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 2048,
    height: 1365,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 2048,
    height: 1365,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 1686,
    height: 1125,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 2048,
    height: 1365,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 2048,
    height: 1365,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    //src:
    //"https://www.facebook.com/CelestaIITP/photos/a.3524289940919656/3524291910919459",
    width: 1000,
    height: 1000,
  },
  {
    src: require("../../assets/img/apple-icon.png"),
    width: 1686,
    height: 1125,
  },
];
