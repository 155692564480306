import React from "react";
import Gallery from "react-photo-gallery";
import { photos } from "./images";

/* popout the browser and maximize to see more rows! -> */



    const Images = () => <Gallery photos={photos} margin= {20} />;

 

export default Images;